import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PropertyCard from "gatsby-theme-starberry-lomondgroup/src/components/PropertyCard/FeatPropertyCard";
import Slider from "react-slick";
import $ from "jquery";


import "gatsby-theme-starberry-lomondgroup/src/components/Features/Features.scss";

//import {FeaturedProperties} from "../../common/queries/common_use_query"

const Features = ({Module, menu_slug}) => {
    
    //console.log("menu_slug", menu_slug);

    const data = useStaticQuery(graphql`
      query FeaturedPropertyQueryTG{
        glstrapi {
            sales_buy_properties:properties(sort:"sort_date:desc",where:{publish:true, department:"residential", search_type:"sales", officeDepartment_nin:["Collection", "investments"], status_in:["For Sale"]}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                reception
                parking
                images
                imagetransforms
                available_from
                extra
                officeDepartment
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
            sales_sell_properties:properties(sort:"price:desc",where:{publish:true, department:"residential", search_type:"sales", officeDepartment_nin:["Collection"], status_in:["Sale Agreed"]}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                reception
                parking
                images
                imagetransforms
                available_from
                extra
                officeDepartment
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
            sales_collection_properties:properties(sort:"price:desc",where:{publish:true, department:"residential", search_type:"sales", officeDepartment:"Collection", status_in:["For Sale", "Sale Agreed"]}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                officeDepartment
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
            to_let_properties:properties(sort:"sort_date:desc",where:{publish:true, department:"residential", search_type:"lettings", status:"To Let"}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                reception
                parking
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
            let_properties:properties(sort:"price:desc",where:{publish:true, department:"residential", search_type:"lettings", status:"Let Agreed"}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                reception
                parking
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
            auction_properties:properties(sort:"price:desc",where:{publish:true, department:"auction", search_type:"sales", status:"For Sale"}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }

            auction_sold:properties(sort:"sort_date:desc",where:{publish:true, department:"auction", search_type:"sales", status:"Sale Agreed"}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }

            student_properties:properties(sort:"price:desc",where:{publish:true, department:"residential", search_type:"lettings", officeDepartment:"Student"}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                officeDepartment
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }

            investment_properties:properties(sort:"price:desc",where:{publish:true, officeDepartment:"investments"}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                officeDepartment
                selling_info
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }

            
        }
    }`)

    var sales_properties = data.glstrapi?.sales_buy_properties;
    var sold_properties = data.glstrapi?.sales_sell_properties;
    var collection_properties = data.glstrapi?.sales_collection_properties;
    var student_properties = data.glstrapi?.student_properties;

    var to_let_properties = data.glstrapi?.to_let_properties;
    var auction_properties = data.glstrapi?.auction_properties;
    var let_properties = data.glstrapi?.let_properties;

    var investment_properties = data.glstrapi?.investment_properties;
    var auction_sold = data.glstrapi?.auction_sold;

    var srch_type = (menu_slug?.includes("rent") || menu_slug?.includes("landlords")) ? "lettings" : "sales"

    const[department, setDepartment] = useState("residential")
    const[search_type, setSearchType] = useState("sales")
    const[properties_list, setPropertiesList] = useState(sales_properties)

    //const{loading, error, data} = FeaturedProperties(department, search_type);

    useEffect(()=>{
        if(Module?.tab_list?.length > 0){
            var split_tab_value = Module?.tab_list[0]?.add_tab ? Module?.tab_list[0]?.add_tab?.split("_") : []
            setDepartment(split_tab_value[0])
            setSearchType(split_tab_value[1])
        }
    },[Module])

    const handleChange = (event) =>{
        var get_tab_key = event.target.attributes.getNamedItem("data-rr-ui-event-key").value;
        var split_tab_value = get_tab_key.split("_")
        setDepartment(split_tab_value[0])
        setSearchType(split_tab_value[1])
        //console.log("get_tab_key", get_tab_key);
        //console.log("tab_key", event.target.attributes.getNamedItem("data-rr-ui-event-key").value)
    }

    useEffect(() =>{
        if(department === "residential" && search_type === "lettings"){
            setPropertiesList(to_let_properties)
        } else if(department === "residential" && search_type === "sold"){
            setPropertiesList(sold_properties)
        } else if(department === "residential" && search_type === "let"){
            setPropertiesList(let_properties)
        } else if(department === "auction" && search_type === "sales"){
            setPropertiesList(auction_properties)
        } else if(department === "auction" && search_type === "sold"){
            setPropertiesList(auction_sold)
        } else if(department === "collection"){
            setPropertiesList(collection_properties)
        } else if(department === "students"){
            setPropertiesList(student_properties)
        } else if(department === "investment"){
            setPropertiesList(investment_properties)
        } else{
            setPropertiesList(sales_properties)
        }
        
    },[department, search_type])
    
    var settings = {
        dots: false,
        arrows: true,
        infinite: properties_list?.length > 4 ? true : false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: true,
        afterChange: (event) => {
            var activeDotEl = $('.features .slick-slider').find('.slick-dots').find('li.slick-active');
            if(activeDotEl.get(0)){
                activeDotEl.get(0).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
            
        },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: properties_list?.length > 3 ? true : false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: properties_list?.length > 2 ? true : false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: properties_list?.length > 1 ? true : false,
                },
            },

        ],
    };


    //console.log("sales_properties", sales_properties, to_let_properties);

    return (
        <React.Fragment>
            {Module?.tab_list?.length > 0 && 
            <section className={`features ${Module?.background_clr} ${Module?.feat_padd}`}>
                
                <Container>
                    <Row>
                        <Col>
                            <h2>{Module?.title ? Module?.title : "Featured Properties"}</h2>
                            <div className={`tab-wrapper`}>
                                {Module?.tab_list?.length > 0 && (
                                    <Tabs
                                        defaultActiveKey={Module?.tab_list[0]?.add_tab}
                                        id="uncontrolled-tab-example"
                                        onClick={handleChange}
                                        className={`tab_count_${Module?.tab_list?.length}`}
                                        >
                                        {Module?.tab_list.map((item, index) => {
                                            return(
                                                <Tab eventKey={item.add_tab} title={item.tab_name} >
                                                    {properties_list?.length > 0 && 
                                                    <Slider {...settings}>
                                                        {properties_list?.map((item, index) => (
                                                            <PropertyCard key={index} FeaturesList={item} />
                                                        ))}
                                                    </Slider>
                                                    }
                                                </Tab>
                                            )
                                        })}                                        
                                    </Tabs>
                                )
                                }                                
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
            }
           
        </React.Fragment>
    );
};
export default Features;
